import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const wiggle = keyframes`
  0% {
    padding-left: -2vw;
  }
  20% {
    padding-left: 0vw;
  }
  40% {
    padding-left: 2vw;
  }
  60% {
    padding-left: 0vw;
  }
  80% {
    padding-left: -2vw;
  }
  100% {
    padding-left: 0vw;
  }
`;
export const GuessWrapper = styled(({ invalid, ...rest }) => <div {...rest} />)`
  display: flex;
  width: 100%;
  transition: all 200ms ease-in-out;
  justify-content:center;
  ${({ invalid }) => invalid && css`
    animation: ${wiggle} 200ms linear 2;
  `}
`;