import styled from 'styled-components';

export const ErrorWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(${({ theme }) => theme.colors.white});
`;