import React from 'react';
import styled, { css } from 'styled-components';
import toggleImg from '../../images/toggle.png';

export const Wrapper = styled.div`
  display: flex;
`;

export const Toggle = styled(({ isEnabled, ...rest }) => <div {...rest}/>)`
  display: flex;
  width: 40px;
  height: 20px;
  border-radius: 10px;
  margin-right: 10px;
  transition: all 300ms ease-in-out;
  background-color: rgb(${({ theme, isEnabled }) => isEnabled ? theme.colors.green : theme.colors.white});
`;

export const Circle = styled(({ isEnabled, ...rest }) => <div {...rest}><img src={toggleImg} alt="Toggle switch" /></div>)`
  display: flex;
  position: relative;
  height: 20px;
  width: 20px;
  padding: 2px;;
  transition: all 300ms ease-in-out;
  border-radius: 10px;
  left: ${({ isEnabled }) => isEnabled ? 0 : '20px'};
  background-color: rgb(${({ theme }) => theme.colors.white});
  top: -2px;

  ${({ isEnabled }) => isEnabled && css`
    transform: rotate(180);
  `}

  > img {
    height: 20px;
  }
`;