import styled from 'styled-components';

export const NotificationWrapper = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-content: center;
  text-align: center;
  background-color: rgb(${({ theme }) => theme.colors.white});
  color: rgb(${({ theme }) => theme.colors.black});
  font-size: 3vh;
  padding: 1vw;
  border-radius: 2vw;
  width: 50vw;
  top: 25vw;
  left: 25vw;
  z-index: 10;
`;