import React from 'react';
import styled, { css } from 'styled-components';
import * as Modal from '../modal/index.styles';

export const Body = styled.span`
  font-size: 2vh;
`;

export const Title = styled(({ isActive, ...rest }) => <Modal.Title {...rest} />)`
  ${({ isActive, theme }) => !isActive && css`
    font-size: 2vh;
    color: rgb(${theme.colors.grey});
  `}
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const Alert = styled(({ showAlert, ...rest }) => <span {...rest} />)`
  position: relative;

  ${({ showAlert }) => showAlert && css`
    ::before {
      content: '';
      display: flex;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      position: absolute;
      right: -10px;
      top: 0px;
      background-color: rgb(${({theme}) => theme.colors.red});
    }
  `}
`;