import { createGlobalStyle } from 'styled-components';

import SceletFont from './fonts/SceletAF.woff2';
import Aurebesh from './fonts/AurebeshAF-Canon.woff2';
import AurebeshDroid from './fonts/Aurebesh-Droid.woff2';

export default createGlobalStyle`
  @font-face {
    font-family: "SceletAF";
    src: url(${SceletFont});
    font-display: swap;
  }
  @font-face {
    font-family: "AurebeshAF";
    src: url(${Aurebesh});
    font-display: swap;
  }
  @font-face {
    font-family: "AurebeshDroid";
    src: url(${AurebeshDroid});
    font-display: swap;
  }
  html {
    scroll-behavior: smooth;
    height: stretch;
  }
  body {
    background-color: rgba(${({ theme }) => theme.colors.black});
    min-height: stretch;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  * {
    font-family: ${({ theme }) => theme.fonts.main};
  }
  a, a:active, a:visited, a:link {
    color: rgb(${({ theme }) => theme.colors.white});
  }
`;