import React from 'react';

import * as Styled from './index.styles';
import * as Modal from '../modal/index.styles';
import * as logger from '../../logging';
import HowToPlay from '../howToPlay';
import News, { lastNewsItemDate } from '../news';
import { useGameContext } from '../gameState';

const Info = ({ onClose }) => {
  const Tabs = [{
    label: 'How to Play',
    content: <HowToPlay />
  },
  {
    label: 'News',
    content: <News />
  }
  ];
  const [viewTab, setViewTab] = React.useState(Tabs[0]);
  const { settings: { lastNewsDay }, actions: { updateNewsDate } } = useGameContext();
  const hasNewNews = lastNewsItemDate > lastNewsDay;

  const switchTabs = (tab) => {
    setViewTab(tab);
    logger.logInfo(logger.EVENTS.OPEN,{ panel: tab.label });
    if (tab.label === 'News') {
      setTimeout(() => {
        updateNewsDate();
      }, 1000);
    }
  };

  return <Modal.Wrapper>
    <Modal.Window>
      <Modal.Close onClick={onClose}>+</Modal.Close>
      <Modal.Body>
        <Styled.Tabs>
          {Tabs.map(tab => <Styled.Alert
            key={tab.label}
            showAlert={hasNewNews && tab.label === 'News'}
          >
            <Styled.Title
              onClick={() => switchTabs(tab)}
              isActive={tab.label === viewTab.label}>
              {tab.label}
            </Styled.Title>
          </Styled.Alert>)}
        </Styled.Tabs>
        <Styled.Body>
          {viewTab.content}
        </Styled.Body>
      </Modal.Body>

    </Modal.Window>
  </Modal.Wrapper>;
};

export default Info;