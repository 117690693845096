import React from 'react';
import Toggle from '../toggle';
import { useGameContext } from '../gameState';

import * as Styled from './index.styles';
import * as Modal from '../modal/index.styles';
import Sync from '../sync';
import * as logger from '../../logging';

const Settings = ({ onClose }) => {
  const { settings, version, actions: { updateSettings } } = useGameContext();
  const [showSync, setShowSync] = React.useState(false);

  const updateColorBlind = () => {
    const newSettings = settings ? { ...settings } : {};
    newSettings.colorBlind = !newSettings.colorBlind;
    updateSettings(newSettings);
  };

  const updateShareA11y = () => {
    const newSettings = settings ? { ...settings } : {};
    newSettings.shareA11y = !newSettings.shareA11y;
    updateSettings(newSettings);
  };

  const updatePlain = () => {
    const newSettings = settings ? { ...settings } : {};
    newSettings.usePlain = !newSettings.usePlain;
    newSettings.useAurebesh = false;
    updateSettings(newSettings);
  };

  const updateAurebesh = () => {
    const newSettings = settings ? { ...settings } : {};
    newSettings.useAurebesh = !newSettings.useAurebesh;
    newSettings.usePlain = false;
    updateSettings(newSettings);
  };

  const emailMe = () => {
    window.open('mailto:aurebeshfiles' + '@gmail.com');
    logger.logInfo(logger.EVENTS.SETTINGS, 'email');
  };

  const openSync = () => {
    setShowSync(true);
    logger.logInfo(logger.EVENTS.SYNC, 'open');
  };

  return <Modal.Wrapper>
    <Modal.Window>
      <Modal.Close onClick={onClose}>+</Modal.Close>
      <Modal.Body>
        <Modal.Title>Settings</Modal.Title>
        <Styled.Body>
          <Styled.Setting>
            <Toggle isEnabled={settings?.colorBlind} onSwitch={updateColorBlind} />
            Color blind mode
          </Styled.Setting>
          <Styled.Setting>
            <Toggle isEnabled={settings?.shareA11y} onSwitch={updateShareA11y} />
            Share with Accessibility
          </Styled.Setting>
          <Styled.Setting>
            <Toggle isEnabled={settings?.usePlain} onSwitch={updatePlain} />
            Use Plain Characters
          </Styled.Setting>
          <Styled.Setting>
            <Toggle isEnabled={settings?.useAurebesh} onSwitch={updateAurebesh} />
            Use Aurebesh Characters
          </Styled.Setting>
          <p><a href="https://www.buymeacoffee.com/davidlozzi" target="_blank" rel="noreferrer">Buy AurebeshFiles a Bantha Milk</a> or <a href="https://www.patreon.com/aurebeshfiles" target="_blank" rel="noreferrer">Support on Patreon</a></p>
          <p>Need Help? Reach out:</p>
          <ul>
            <li><a href="https://starwars.guide/swordle-word-list" title="View SWordle Word List" target="_blank" rel="noreferrer">View the entire word list</a></li>
            <li>Twitter: <a href="https://twitter.com/AurebeshFiles">@AurebeshFiles</a></li>
            <li>Reddit: <a href="https://www.reddit.com/r/swordle/">swordle</a></li>
            <li>Email: <a href="#" onClick={emailMe}>aurebeshFiles@gmail.com</a></li>
          </ul>
          <p>Please include the following with your message: &quot;g{settings.lastDayIndexRefresh}v{version}&quot;.</p>
          <Styled.Button onClick={openSync}>Import/Export Stats</Styled.Button>
          {showSync && <Sync onClose={() => setShowSync(false)} />}
        </Styled.Body>
      </Modal.Body>
    </Modal.Window>
  </Modal.Wrapper>;
};

export default Settings;