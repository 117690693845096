import styled from 'styled-components';

export const AppWrapper = styled.div`
  background-color: rgb(${({ theme }) => theme.colors.black});
  /* height: 100vh; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const App = styled.div`
  max-width: 500px;
  width: 100%;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;