import React from 'react';
import styled, { css } from 'styled-components';

export const KeyboardWrapper = styled(({ showKeyboard, ...rest}) => <div {...rest} />)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1vh;
  width: 100%;
  max-width: 500px;
  opacity: 0;
  transition: all 500ms ease-in-out;

  ${({ showKeyboard }) => showKeyboard && css`
    opacity: 1;
  `}
`;

export const Keyline = styled.div`
  display: flex;
  justify-content: center;
`;