import React from 'react';
import { obfs } from '../../obfs';
import { useGameContext } from '../gameState';
import Notification from '../notification';
import * as logger from '../../logging';

import * as Styled from './index.styles';

const Export = () => {
  const dobfs = obfs('sync');
  const { stats } = useGameContext();
  const [statsStr, setStatsStr] = React.useState('');
  const [isShared, setIsShared] = React.useState(false);

  const copy = () => {
    navigator.clipboard.writeText(statsStr);
    setIsShared(true);
    logger.logInfo(logger.EVENTS.SYNC, 'export copied');
  };

  React.useEffect(() => {
    console.log(stats);
    const obfStats = dobfs(JSON.stringify(stats));
    setStatsStr(obfStats);
  }, []);

  return <Styled.Wrapper>
    <Styled.Note>Click Copy to Clipboard below and then go to your new browser and use Import above.</Styled.Note>
    <Styled.Data>{statsStr}</Styled.Data>
    <Styled.Button onClick={copy}>Copy to Clipboard</Styled.Button>
    {isShared && <Notification message="Copied" timeout={1000} onHide={() => setIsShared(false)} />}
  </Styled.Wrapper>;
};

export default Export;