import styled from 'styled-components';

export const Content = styled.p`
  margin-top: 50px;
`;

export const ShowHint = styled.button`
  border: 0;
  border-radius: 10px;
  font-size: 20px;
  background-color: rgb(${({ theme }) => theme.colors.green});
  padding: 10px;
  color: rgb(${({ theme }) => theme.colors.black});
  text-transform: uppercase;
  margin: 10px 0 10px 15px;
  @media only screen and (max-width: 700px) {
    font-size: 15px;
  }

  :disabled {
    background-color: rgb(${({ theme }) => theme.colors.grey});
  }
`;

export const Hint = styled.p`
  text-align: center;
  margin: 50px;
`;