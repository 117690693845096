import React, { useContext } from 'react';
import { DateTime } from 'luxon';
import { ThemeProvider } from 'styled-components';
import defaultTheme, { fonts, defaultColors, colorBlind } from '../../defaultTheme';
import vals from '../../values.txt';
import { unobfs, functin } from '../../obfs';
import * as logger from '../../logging';
import keys from './keys.json';

const gameContext = React.createContext({});

export const useGameContext = () => useContext(gameContext);
const initialState = {
  guesses: [
    [
      { letter: '', state: 0 },
      { letter: '', state: 0 },
      { letter: '', state: 0 },
      { letter: '', state: 0 },
      { letter: '', state: 0 }
    ],
    [
      { letter: '', state: 0 },
      { letter: '', state: 0 },
      { letter: '', state: 0 },
      { letter: '', state: 0 },
      { letter: '', state: 0 }
    ],
    [
      { letter: '', state: 0 },
      { letter: '', state: 0 },
      { letter: '', state: 0 },
      { letter: '', state: 0 },
      { letter: '', state: 0 }
    ],
    [
      { letter: '', state: 0 },
      { letter: '', state: 0 },
      { letter: '', state: 0 },
      { letter: '', state: 0 },
      { letter: '', state: 0 }
    ],
    [
      { letter: '', state: 0 },
      { letter: '', state: 0 },
      { letter: '', state: 0 },
      { letter: '', state: 0 },
      { letter: '', state: 0 }
    ],
    [
      { letter: '', state: 0 },
      { letter: '', state: 0 },
      { letter: '', state: 0 },
      { letter: '', state: 0 },
      { letter: '', state: 0 }
    ]
  ],
  currentGuess: 0,
  actions: {},
  invalidGuess: false,
  gameOver: false,
  winGame: false,
  showModal: false,
  keys,
  theWord: '',
  gameNumber: -1,
  showingHint: false,
  useHint: false
};

const settingsDefault = {
  colorBlind: false,
  shareA11y: false,
  usePlain: false,
  useAurebesh: false,
  lastDayIndexRefresh: 0,
  lastNewsDay: ''
};

const statsDefault = {
  gamesPlayed: 0,
  winGuessCount: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0
  },
  lastPlayed: null,
  gamesWon: 0,
  streak: 0,
  maxStreak: 0,
  hintsUsed: 0
};

const GameContext = ({ children }) => {
  const [theWord, setTheWord] = React.useState('');
  const [theme, setTheme] = React.useState(defaultTheme);
  const [values, setValues] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const dobfs = unobfs(functin);

  const [gameState, setGameState] = React.useState(JSON.parse(localStorage.getItem('swordle')) || initialState);
  const [settings, setSettings] = React.useState(JSON.parse(localStorage.getItem('swordle-settings')) || settingsDefault);

  const [stats, setStats] = React.useState(JSON.parse(localStorage.getItem('swordlestats')) || statsDefault);

  const updateStats = (won) => {
    const newStats = { ...statsDefault, ...stats };
    newStats.gamesPlayed += 1;
    if (won) {
      newStats.gamesWon += 1;
      newStats.streak += 1;
      if (newStats.streak > newStats.maxStreak) newStats.maxStreak = newStats.streak;
      newStats.winGuessCount[gameState.currentGuess + 1] += 1;
    } else {
      newStats.streak = 0;
    }
    newStats.lastPlayed = new Date();
    if (gameState.useHint) {
      newStats.hintsUsed += 1;
    }
    localStorage.setItem('swordlestats', JSON.stringify(newStats));
    setStats(newStats);
  };

  const importStats = (statsObfs) => {
    const uobfs = unobfs('sync');
    const newStats = uobfs(statsObfs);
    localStorage.setItem('swordlestats', newStats);
    setStats(JSON.parse(newStats));
  };

  const addFromKeyboard = (letter) => {
    if (keys.some(line => line.some(l => l.letter === letter.toLowerCase()))) {
      addToGuess(letter.toLowerCase());
    }
  };

  const addToGuess = (letter) => {
    if (!gameState.gameOver) {
      const guesses = [...gameState.guesses];
      guesses[gameState.currentGuess].some(l => {
        if (l.letter.length === 0) {
          l.letter = letter;
          return true;
        }
        return false;
      });
      setGameState((prevData) => ({ ...prevData, guesses }));
    }
  };

  const deleteFromGuess = React.useCallback(() => {
    if (!gameState.gameOver) {
      if (gameState.guesses[gameState.currentGuess].some(g => g.letter.length > 0)) {
        const guesses = [...gameState.guesses];
        guesses[gameState.currentGuess].reverse().some(l => {
          if (l.letter.length > 0) {
            l.letter = '';
            return true;
          }
          return false;
        });
        guesses[gameState.currentGuess].reverse();
        setGameState((prevData) => ({ ...prevData, guesses }));
      }
    }
  }, [gameState]);

  const isAWord = (guess) => {
    const guessWord = guess.reduce((a, b) => a + b.letter, '');
    return values.some(w => w.word === guessWord.toLowerCase());
  };

  const updateKeyState = (letter) => {
    const keys = [...gameState.keys];
    keys.forEach(line => {
      line.forEach(l => {
        if (l.letter === letter.letter && l.state < letter.state) {
          l.state = letter.state;
        }
      });
    });
    setGameState((prevState) => ({ ...prevState, keys }));
  };

  const submitWord = () => {
    if (!gameState.gameOver && gameState.guesses[gameState.currentGuess]?.every(l => l.letter.length > 0)) {
      if (isAWord(gameState.guesses[gameState.currentGuess])) {
        const guesses = [...gameState.guesses];
        const wordArr = theWord.word.split('');
        let guessedWord = '';
        const updatedGuesses = [];

        // finds and highlights greens (3)
        guesses[gameState.currentGuess].forEach((l, idx) => {
          guessedWord += l.letter;
          if (l.letter === wordArr[idx]) {
            l.state = 3;
            updatedGuesses.push(l);
          }
          updateKeyState(l);
        });

        // finds and highlights yellow (2) and grays (1) that are not 3
        guesses[gameState.currentGuess].forEach((l, idx) => {
          if (l.state !== 3) {
            if (wordArr.some(t => t === l.letter) && !updatedGuesses.some(lt => lt.letter === l.letter && (lt.state === 3 || lt.state === 2))) { // if it already matches green (3) then shouldn't be yellow
              l.state = 2;
            } else {
              // if the letters have been guessed, highlight the correct number of letters
              // i.e. APHRA is the win, AMARA should highlight 2 As, first and last, middle one should be grey
              const wordArrCnt = wordArr.filter(t => t === l.letter).length;
              const guessCnt = updatedGuesses.filter(t => t.letter === l.letter).length;
              if (wordArrCnt > 0 && wordArrCnt > guessCnt) {
                l.state = 2;
              } else {
                l.state = 1;
              }
            }
            updatedGuesses.push(l);
            updateKeyState(l);
          }
        });
        const nextGuess = gameState.currentGuess + 1;
        setGameState((prevData) => ({ ...prevData, guesses, currentGuess: nextGuess }));
        if (guessedWord.toLowerCase() === theWord.word) {
          setGameState((prevData) => ({ ...prevData, gameOver: true, winGame: true, theWord }));
          updateStats(true);
          logger.logInfo(logger.EVENTS.GUESS, { win: 'yes', guessedWord, currentGuess: gameState.currentGuess });
        } else if (nextGuess === 6) {
          logger.logInfo(logger.EVENTS.GUESS, { win: 'no', guessedWord, currentGuess: gameState.currentGuess });
          updateStats(false);
          setGameState((prevData) => ({ ...prevData, gameOver: true, winGame: false, theWord }));
        } else {
          logger.logInfo(logger.EVENTS.GUESS, { win: 'tbd', guessedWord, currentGuess: gameState.currentGuess });
        }

      } else {
        const guessedWord = gameState.guesses[gameState.currentGuess].reduce((a, b) => a + b.letter, '');
        setGameState((prevState) => ({ ...prevState, invalidGuess: true }));
        logger.logInfo(logger.EVENTS.INVALID, { win: 'tbd', guessedWord, currentGuess: gameState.currentGuess });
      }
    }
  };

  const showStats = () => {
    setGameState((prevState) => ({ ...prevState, showModal: true }));
  };

  const closeModal = () => {
    setGameState((prevState) => ({ ...prevState, showModal: false }));
  };

  const showHint = () => {
    let useHint = true;
    if (gameState.gameOver) {
      useHint = false;
    }
    setGameState((prevState) => ({ ...prevState, showingHint: true, useHint }));
  };

  const updateSettings = (sets) => {
    setSettings({ ...settingsDefault, ...sets });
    localStorage.setItem('swordle-settings', JSON.stringify(sets));
  };

  const updateNewsDate = () => {
    updateSettings({ ...settings, lastNewsDay: DateTime.now().toISODate() });
  };

  React.useEffect(() => {
    logger.logInfo(logger.EVENTS.SETTINGS, settings);
    if (settings.colorBlind) {
      setTheme((prevTheme) => ({
        ...prevTheme,
        colors: colorBlind
      }));
    } else {
      setTheme((prevTheme) => ({
        ...prevTheme,
        colors: defaultColors
      }));
    }
    if (settings.usePlain) {
      setTheme((prevTheme) => ({
        ...prevTheme, fonts: {
          title: fonts.plain,
          main: fonts.plain
        }
      }));
    }
    if (settings.useAurebesh) {
      setTheme((prevTheme) => ({
        ...prevTheme, fonts: {
          title: fonts.aurebeshDroid,
          main: fonts.aurebesh
        }
      }));
    }
    if (!settings.usePlain && !settings.useAurebesh) {
      setTheme((prevTheme) => ({
        ...prevTheme, fonts: {
          title: fonts.scelet,
          main: fonts.plain
        }
      }));
    }
  }, [settings]);

  React.useEffect(() => {
    localStorage.setItem('swordle', JSON.stringify(gameState));
  }, [gameState]);

  React.useEffect(() => {
    const getVals = async () => {
      const resp = await fetch(vals);
      const txt = await resp.text();
      const _values = JSON.parse(dobfs(txt));
      setValues(_values);
      const dayIndex = Math.ceil(DateTime.now().diff(DateTime.local(2022, 1, 29), ['days']).values.days);
      // attempt at a cache buster, reloading window should do it
      if (settings.lastDayIndexRefresh !== dayIndex) {
        updateSettings({ ...settings, lastDayIndexRefresh: dayIndex });
        logger.logInfo('refresh');
        window.location.reload();
      }
      const startIndex = _values.findIndex(w => w.word === 'start');
      const wordIndex = startIndex + dayIndex - 289 - 419; // 289 when we shuffled the words, 419 after the 2nd shuffle
      const myWord = _values[wordIndex];
      setTheWord(myWord);
      const newGameState = { ...initialState, gameNumber: dayIndex, settings: gameState.settings, showModal: false, theWord: myWord };
      if (gameState.gameNumber !== dayIndex) {
        setGameState(newGameState);
      }
      logger.logInfo(logger.EVENTS.LOGON, { ...newGameState, settings, stats, dayVals: { dayIndex, startIndex, myWord } });
      setLoading(false);
    };
    getVals();
  }, []);

  React.useEffect(() => {
    if (gameState.invalidGuess) {
      setTimeout(() => {
        setGameState((prevState) => ({ ...prevState, invalidGuess: false }));
      }, 1000);
    }
  }, [gameState.invalidGuess]);

  React.useEffect(() => {
    if (gameState.gameOver) {
      const timeOut = gameState.winGame ? 1500 : 5000;
      setTimeout(() => {
        setGameState((prevState) => ({ ...prevState, showModal: true }));
      }, timeOut);
    }
  }, [gameState.gameOver]);

  return <gameContext.Provider value={{ ...gameState, theWord, loading, settings, version: '65', stats, actions: { addToGuess, submitWord, deleteFromGuess, addFromKeyboard, closeModal, updateSettings, showStats, showHint, updateNewsDate, importStats } }}>
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  </gameContext.Provider>;
};

export default GameContext;
