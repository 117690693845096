import React from 'react';
import * as logger from '../../logging';
import * as AppStyles from '../../App.styles';
import GlobalStyles from '../../globalStyles';
import { ThemeProvider } from 'styled-components';
import theme from '../../defaultTheme';
import logo from '../../images/logo.png';
import * as Styled from './index.styles';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logger.logError(logger.EVENTS.APP, {error, errorInfo});
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ThemeProvider theme={theme}>
        <GlobalStyles />
        <AppStyles.AppWrapper>
          <Styled.ErrorWrapper>
            <img src={logo} alt="SWordle" style={{ width: '200px' }} />
            <p>&nbsp;</p>
            <h1>Kriff</h1>
            <h2>It appears we have a bad motivator.</h2>
            <p>We&apos;re sorry for this inconvience, some padawan really messed up. There may be a chance a simple refresh in your browser will fix the issue.
            </p>
            <p>However, if that doesn&apos;t work, we probably know about the error, but just in case, feel free to let us know through the following options:</p>
            <ul>
              <li><a href="mailto:aurebeshfiles@gmail.com?SUBJECT=SWordle Error">email AurebeshFiles@gmail.com</a></li>
              <li><a href="https://twitter.com/aurebeshfiles" target="_blank" rel="noreferrer">tweet @AurebeshFiles</a></li>
              <li><a href="https://www.reddit.com/r/swordle" target="_blank" rel="noreferrer">subreddit SWordle</a></li>
            </ul>
            <p>Thank you for your patience, we&apos;ll get this sorted out real soon.</p>
            <p>For light and life.</p>
          </Styled.ErrorWrapper>
        </AppStyles.AppWrapper>
      </ThemeProvider>;
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;