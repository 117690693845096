import React from 'react';
import { useGameContext } from '../gameState';
import DeleteKey from '../../images/delete.png';
import EnterKey from '../../images/enter.png';

import * as Styled from './index.styles';

const Key = ({ letter }) => {
  const { actions: { addToGuess, submitWord, deleteFromGuess } } = useGameContext();

  const addLetterToGuess = (letter) => {
    addToGuess(letter);
  };
  return <>
    {letter.letter === '^' && <Styled.KeyWrapper icon noBorder onClick={submitWord}><img src={EnterKey} alt="Enter Key" /></Styled.KeyWrapper>}
    {letter.letter === '<' && <Styled.KeyWrapper icon onClick={deleteFromGuess}><img src={DeleteKey} alt="Delete Key" /></Styled.KeyWrapper>}
    {letter.letter !== '<' && letter.letter !== '^' && <Styled.KeyWrapper onClick={() => addLetterToGuess(letter.letter)} styledState={letter.state}>{letter.letter}</Styled.KeyWrapper>}
  </>;
};

export default Key;