export const defaultColors = {
  black: '30,30,30',
  grey: '100,100,100',
  white: '255,255,255',
  green: '141,198,63',
  yellow: '224,196,56',
  red: '214,42,39'
};

export const colorBlind = {
  black: '30,30,30',
  grey: '100,100,100',
  white: '255,255,255',
  green: '245,121,58',
  yellow: '133,192,249',
  red: '214,42,39'
};

export const fonts = {
  scelet: '\'SceletAF\', Helvetica, sans-serif',
  plain: 'Helvetica, sans-serif',
  aurebesh: 'AurebeshAF, Helvetica, sans-serif',
  aurebeshDroid: 'AurebeshDroid, Helvetica, sans-serif'
};

const theme = {
  colors: defaultColors,
  fonts: {
    title: fonts.scelet,
    main: fonts.plain
  }
};

export default theme;