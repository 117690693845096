import { datadogRum } from '@datadog/browser-rum';

export const initializeDataDog = () => {
  datadogRum.init({
    applicationId: '7975328a-3566-4d8f-a25d-cade07e5ec2e',
    clientToken: 'pub82824e5dd46a0d42b480ed549eea8bfa',
    site: 'datadoghq.com',
    service: 'swordle',
    env: 'prod',
    version: '65',
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'allow'
  });
    
  datadogRum.startSessionReplayRecording();
};

export const EVENTS = {
  LOGON: 'LOGON',
  SETUP: 'SETUP',
  GUESS: 'GUESS',
  INVALIDGUESS: 'INVALIDGUESS',
  SETTINGS: 'SETTINGS',
  OPEN: 'OPEN',
  SHARE: 'SHARE',
  APP: 'APP',
  SYNC: 'SYNC'
};

export const logInfo = (event, data) => {
  datadogRum.addAction(event, data);
};

export const logError = (event, data) => {
  datadogRum.addAction(`ERROR ${event}`, data);
};