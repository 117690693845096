import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const jump = keyframes`
  0% {
    transform: translateY(-2px);
  }
  20% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(2px);
  }
  60% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0);
  }`;

export const BoxWrapper = styled(({ styledState, ...rest }) => <div {...rest} />)`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 7vh;
  height: 7vh;
  /* max-height: 50px;
  max-width: 50px; */
  border: solid rgb(${({ theme }) => theme.colors.grey}) 3px;
  border-radius: 10%;
  font-size: 5vh;
  font-family: ${({ theme }) => theme.fonts.title};
  text-transform: uppercase;
  margin: 1vh 1vh 0 0;
  color: rgb(${({ theme }) => theme.colors.white});
  transition: all 300ms ease-in-out;

  ${({ styledState }) => styledState === 0 &&
    css`
      background-color: transparent;
      border-color: rgb(${({ theme }) => theme.colors.grey});
    `
}
  ${({ styledState }) => styledState === 1 &&
    css`
      background-color: rgb(${({ theme }) => theme.colors.grey});
      border-color: rgb(${({ theme }) => theme.colors.grey});
      color: rgb(${({ theme }) => theme.colors.white});
      animation: ${jump} 200ms linear 2;
    `
}
  ${({ styledState }) => styledState === 2 &&
    css`
      background-color: rgb(${({ theme }) => theme.colors.yellow});
      border-color: rgb(${({ theme }) => theme.colors.yellow});
      color: rgb(${({ theme }) => theme.colors.black});
      animation: ${jump} 200ms linear 2;
    `
}
  }
  ${({ styledState }) => styledState === 3 &&
    css`
      background-color: rgb(${({ theme }) => theme.colors.green});
      border-color: rgb(${({ theme }) => theme.colors.green});
      color: rgb(${({ theme }) => theme.colors.black});
      animation: ${jump} 200ms linear 2;
    `
}
`;