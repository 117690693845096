import React from 'react';
import { useGameContext } from '../gameState';
import Key from '../key';

import * as Styled from './index.styles';


const Keyboard = () => {
  const { keys, loading } = useGameContext();
  const [showKeyboard, setShowKeyboard] = React.useState(!loading);

  React.useEffect(() => {
    if (!loading) {
      setShowKeyboard(true);
    }
  }, [loading]);

  return <Styled.KeyboardWrapper showKeyboard={showKeyboard}>
    {keys.map((line, idx) =>
      <Styled.Keyline key={`keyboard-${idx}`}>
        {line.map(l => <Key letter={l} key={l.letter} />)}
      </Styled.Keyline>
    )
    }
  </Styled.KeyboardWrapper>;
};

export default Keyboard;