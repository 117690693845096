import React from 'react';
import NewsData from '../../news.json';

import * as Styled from './index.styles';

export const lastNewsItemDate = NewsData.filter(n => new Date(n.date) <= new Date()).sort((a, b) => a.date < b.date ? 1 : -1)[0].date;

const News = () => 
  <Styled.List>
    {NewsData
      .filter(n => new Date(n.date) <= new Date())
      .sort((a, b) => a.date < b.date ? 1 : -1)
      .map(news => <Styled.NewsItem key={news.title}>
        <Styled.NewsTitle>{news.title}</Styled.NewsTitle>
        <Styled.NewsBody dangerouslySetInnerHTML={{ __html: news.message }} />
        <Styled.NewsDate>{news.date}</Styled.NewsDate>
      </Styled.NewsItem>)}
  </Styled.List>;

export default News;