import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import BadDomain from './components/baddomain';

function checkDomain() {
  if ((window.location.hostname === 'wordle.starwars.guide' || window.location.hostname === 'localhost')
    && window.self === window.top) { // checks for running in iframe
    return <React.StrictMode>
      <App />
    </React.StrictMode>;
  } else {
    return <React.StrictMode>
      <BadDomain />
    </React.StrictMode>;
  }
}
ReactDOM.render(checkDomain(),
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
