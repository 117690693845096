import styled from 'styled-components';

export const Wrapper = styled.div`
`;

export const DataInput = styled.textarea`
  width: 100%;
  height: 250px;
  border: 1px solid rgba(${({ theme }) => theme.colors.white});;
  color: rgba(${({ theme }) => theme.colors.white});
  background-color: rgba(${({ theme }) => theme.colors.grey});
  overflow-wrap: anywhere;
  font-family: 'Courier New', Arial, sans-serif;
  font-size: 16px;
`;

export const Button = styled.button`
  border: 0;
  border-radius: 10px;
  font-size: 20px;
  background-color: rgb(${({ theme }) => theme.colors.green});
  padding: 10px;
  color: rgb(${({ theme }) => theme.colors.black});
  text-transform: uppercase;
  margin: 10px 0 10px 15px;
  @media only screen and (max-width: 700px) {
    font-size: 15px;
  }
`;

export const Note = styled.p`
  font-size: 16px;
`;