import React from 'react';

import * as Styled from './index.styles';

const Toggle = ({ isEnabled, onSwitch }) => {

  return <Styled.Wrapper>
    <Styled.Toggle isEnabled={isEnabled} onClick={onSwitch}>
      <Styled.Circle isEnabled={isEnabled}/>
    </Styled.Toggle>
  </Styled.Wrapper>;
};

export default Toggle;