import React from 'react';

const Ad = ({ closeAd, adSlot, adFormat = 'auto', layoutKey }) => {

  React.useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error('Ads:', e);
    }
  }, []);

  return <ins className="adsbygoogle"
    style={{ display: 'block' }}
    data-ad-client="ca-pub-6056590143595280"
    data-ad-slot={adSlot}
    data-ad-format={adFormat}
    data-ad-layout-key={layoutKey}
    data-full-width-responsive="true"></ins>;

};

export default Ad;