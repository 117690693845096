import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 50px 0px;
  background-color: rgba(${({ theme }) => theme.colors.black},0.8);
  z-index: 10;
`;

export const Window = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgb(${({ theme }) => theme.colors.black});
  border: solid 2px rgb(${({ theme }) => theme.colors.grey});
  width: 80vw;
  max-width: 550px;
  color: rgb(${({ theme }) => theme.colors.white});
`;

export const Close = styled.button`
  display: flex;
  align-self: flex-end;
  font-size: 40px;
  color: rgb(${({ theme }) => theme.colors.grey});
  background: transparent;
  border: 0;
  padding-right: 10px;
  font-family: ${({ theme }) => theme.fonts.title};
  transform: rotate(45deg);
`;

export const Body = styled.div`
  padding: 0px 30px 30px 30px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  display: flex;
  margin: 0;
  font-size: 2.5vh;
  text-transform: uppercase;
  justify-content: center;
  justify-items: center;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.title};
  margin-bottom: 10px;
`;
