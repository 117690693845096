import styled from 'styled-components';

export const List = styled.div`
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: none;
`;

export const NewsItem = styled.article`
`;

export const NewsTitle = styled.h3`
  margin-top: 30px;
  font-size: 2.5vh;
  font-weight: bold;
  margin-bottom: 10px;
  border-bottom: solid 1px rgb(${({ theme }) => theme.colors.grey});
`;

export const NewsBody = styled.p`
  margin-bottom: 0;
  line-height: 2.4vh;
`;

export const NewsDate = styled.p`
  font-size: 14px;
  margin-top: 5px;
  font-style: italic;
`;