import React from 'react';
import GameContext from './components/gameState';
import Board from './components/board';
import Keyboard from './components/keyboard';
import Stats from './components/stats';
import Header from './components/header';
import GlobalStyles from './globalStyles';
import { initializeDataDog } from './logging';
import * as Styled from './App.styles';
import ErrorBoundary from './components/ErrorBoundary';

initializeDataDog();

function App() {
  return <ErrorBoundary>
    <GameContext>
      <GlobalStyles />
      <Styled.AppWrapper>
        <Header />
        <Styled.App>
          <Board />
          <Keyboard />
          <Stats />
        </Styled.App>
      </Styled.AppWrapper>
    </GameContext>
  </ErrorBoundary>;
}

export default App;
