import React from 'react';
import Box from '../box';
import { useGameContext } from '../gameState';
import Guess from '../guess';
import Notification from '../notification';

import * as Styled from './index.styles';

const Board = () => {
  const gameContext = useGameContext();
  const [winMessage, setWinMessage] = React.useState('');
  const {
    guesses,
    invalidGuess,
    currentGuess,
    gameOver,
    winGame,
    theWord,
    actions: {
      addFromKeyboard,
      submitWord,
      deleteFromGuess
    }
  } = gameContext;

  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === 'Enter') {
        submitWord();
      } else if (event.code === 'Backspace' || event.code === 'Delete') {
        deleteFromGuess();
      } else {
        addFromKeyboard(event.key);
      }
    };
    window.document.addEventListener('keydown', handleKeyDown);

    return () => {
      window.document.removeEventListener('keydown', handleKeyDown);
    };
  }, [addFromKeyboard, deleteFromGuess, submitWord, theWord]);

  React.useEffect(() => {
    if (gameOver && winGame) {
      switch (currentGuess) {
      case 1:
        setWinMessage('Great, kid, don\'t get cocky.');
        break;
      case 2:
        setWinMessage('The force is strong with this one');
        break;
      case 3:
        setWinMessage('Your focus determines your reality.');
        break;
      case 4:
        setWinMessage('As long as there\'s light, we\'ve got a chance');
        break;
      case 5:
        setWinMessage('Never tell me the odds');
        break;
      case 6:
        setWinMessage('Do or do not. There is no try');
        break;
      default:
        setWinMessage('');
        break;
      }
    }
  }, [gameOver, winGame, currentGuess]);

  return <Styled.BoardWrapper>
    {invalidGuess && <Notification message="word not on list" timeout={1000} />}
    {gameOver && winGame && <Notification message={winMessage} timeout={2000} />}
    {gameOver && !winGame && <Notification message={`Dank farrik. word: ${theWord?.word ? theWord.word.toUpperCase() : theWord.toUpperCase()}`} timeout={3000} />}
    {guesses.map((g, gidx) =>
      <Guess key={gidx} invalid={gidx === currentGuess && invalidGuess}>
        {g.map((l, lidx) => <Box letter={l} key={`${gidx}-${lidx}`} />) }
      </Guess>
    )}
  </Styled.BoardWrapper>;
};

export default Board;