import React from 'react';

import * as Styled from './index.styles';

const Notification = ({ message, timeout, onHide }) => {
  const [showIt, setShowIt] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setShowIt(false);
      onHide && onHide();
    }, timeout);
    
    return () => {
      setShowIt(true);
    };
  }, [timeout]);

  return <>
    {showIt && <Styled.NotificationWrapper>{message}</Styled.NotificationWrapper>}
  </>;
};

export default Notification;