import React from 'react';
import styled, { css } from 'styled-components';

export const KeyWrapper = styled(({ icon, styledState, noBorder, ...rest}) => <div {...rest} />)`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  ${({ noBorder }) => !noBorder && css`
    border: solid 1px rgb(${({ theme }) => theme.colors.green});
    border-radius: 3vw;
  `}
  color: rgb(${({ theme }) => theme.colors.green});;
  width: 4vh;
  height: 5.5vh;
  max-width: 42px;
  max-height: 65px;
  margin: 2px;
  font-family: ${({ theme }) => theme.fonts.title};
  font-size: 3.5vh;
  ${({ icon }) => icon && css`
    height: 5.5vh;
    width: 7.5vh;
    max-width: 80px;
  `}

  > img {
    height: 4.5vh;
    ${({ noBorder }) => noBorder && css`
      max-height: 40px;
    `}
  }

  &:active {
    background-color: rgb(${({ theme }) => theme.colors.green});;
  }

  ${({ styledState }) => styledState === 1 &&
    css`
      background-color: rgb(${({ theme }) => theme.colors.grey});;
      border-color: rgb(${({ theme }) => theme.colors.grey});;
      color: rgb(${({ theme }) => theme.colors.white});;
    `
}
  ${({ styledState }) => styledState === 2 &&
    css`
      background-color: rgb(${({ theme }) => theme.colors.yellow});;
      border-color: rgb(${({ theme }) => theme.colors.yellow});;
      color: rgb(${({ theme }) => theme.colors.black});;
    `
}
  }
  ${({ styledState }) => styledState === 3 &&
    css`
      background-color: rgb(${({ theme }) => theme.colors.green});;
      border-color: rgb(${({ theme }) => theme.colors.green});;
      color: rgb(${({ theme }) => theme.colors.black});
    `
}
`;