import React from 'react';
import Import from '../import';
import Export from '../export';

import * as Styled from './index.styles';
import * as Modal from '../modal/index.styles';

const Sync = ({ onClose }) => {
  const Tabs = [{
    label: 'Export',
    content: <Export />
  },
  {
    label: 'Import',
    content: <Import closeModal={onClose} />
  }
  ];
  const [currentTab, setCurrentTab] = React.useState(Tabs[0]);

  const switchTabs = (tab) => {
    setCurrentTab(tab);
  };

  return <Modal.Wrapper>
    <Modal.Window>
      <Modal.Close onClick={onClose}>+</Modal.Close>
      <Modal.Body>
        <Modal.Title>Sync your Stats</Modal.Title>
        <Styled.Body>
          <Styled.Note>Use this screen to import and export your stats.</Styled.Note>
          <Styled.Tabs>
            {Tabs.map(tab => <Styled.Tab
              key={tab.label}
              onClick={() => switchTabs(tab)}
              isActive={tab.label === currentTab.label}
            >
              {tab.label}
            </Styled.Tab>
            )}
          </Styled.Tabs>
          <Styled.Content>
            {currentTab.content}
          </Styled.Content>
        </Styled.Body>
      </Modal.Body>

    </Modal.Window>
  </Modal.Wrapper>;
};

export default Sync;