import styled from 'styled-components';

export const Body = styled.span`
  font-size: 2vh;
  display: flex;
  flex-direction: column;
`;

export const Example = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
`;

export const Setting = styled.div`
  display: flex;
  margin: 15px 0;
`;

export const Button = styled.button`
  background-color: transparent;
  text-decoration: underline;
  display: flex;
  border: 0;
  width: fit-content;
  font-size: 1.8vh;
  text-align: left;
  color: rgba(${({ theme }) => theme.colors.white});
  cursor: pointer;
`;