import React from 'react';
import { useGameContext } from '../gameState';
import Notification from '../notification';
import Ad from '../ad';
import OnTarget from '../../images/ontarget.gif';
import * as logger from '../../logging';
import * as Styled from './index.styles';
import * as Modal from '../modal/index.styles';

const Stats = () => {
  const { showModal, stats, gameNumber, gameOver, currentGuess, guesses, winGame, theWord, settings, useHint, actions: { closeModal } } = useGameContext();
  const [isShared, setIsShared] = React.useState(false);
  const [goWide, setGoWide] = React.useState(false);
  const [maxWidthDenom, setMaxWidthDenom] = React.useState(0);
  const [showAd, setShowAd] = React.useState(false);
  const [adShown, setAdShown] = React.useState(false);


  const closeAd = () => {
    setShowAd(false);
    setAdShown(true);
  };

  const shareGame = () => {
    try {
      let shareString = `SWordle #${gameNumber} `;
      if (settings?.shareA11y) {
        shareString += `won in ${winGame ? currentGuess : 'X'}, out of 6\n\n`;
      } else {
        shareString += `${winGame ? currentGuess : 'X'}/6\n\n`;
      }
      guesses.forEach((g, idx) => {
        if (idx < currentGuess) {
          if (settings?.shareA11y) {
            shareString += `Row ${idx + 1}: `;
            let cnt = 1;
            g.forEach((l, lidx) => {
              if (l.state === g[lidx + 1]?.state) { // same value is next, so add them up
                cnt += 1;
              } else {
                if (l.state <= 1) {
                  shareString += `${cnt} wrong`;
                } else if (l.state === 2) {
                  shareString += `${cnt} misplaced`;
                } else if (l.state === 3) {
                  shareString += `${cnt} right`;
                }
                if (lidx < 4) {
                  shareString += ', ';
                } else {
                  shareString += '.\n';
                }
                cnt = 1;
              }
            });
          } else {
            g.forEach(l => {
              if (l.state <= 1) {
                shareString += '⬛️';
              } else if (l.state === 2) {
                shareString += settings?.colorBlind ? '🟦' : '🟨';
              } else if (l.state === 3) {
                shareString += settings?.colorBlind ? '🟧' : '🟩';
              }
            });
            shareString += '\n';
          }
        }
      });
      if (useHint) {
        shareString += 'hint used';
      }
      shareString += '\n\nhttps://wordle.starwars.guide';
      if (navigator.share) {
        navigator.share({
          text: shareString
        });
      } else {
        navigator.clipboard.writeText(shareString);
        setIsShared(true);
      }
    } catch (ex) {
      if (!ex.toString().includes('AbortError')) { // when uer cancels share it errors
        logger.logError(logger.EVENTS.SHARE, { content: 'game', error: ex.toString(), gameNumber, settings, winGame, stats, showModal });
      }
    }
  };

  const emojis = {
    1: '⒈',
    2: '⒉',
    3: '⒊',
    4: '⒋',
    5: '⒌',
    6: '⒍'
  };

  const shareStats = () => {
    try {
      let shareString = 'My SWordle Stats\n\n';
      shareString += `${Math.round(stats.gamesWon / stats.gamesPlayed * 100)}%, ${stats.gamesPlayed} played, ${stats.streak} streak, ${stats.maxStreak} max, ${stats.hintsUsed} hints\n`;

      [1, 2, 3, 4, 5, 6].map(num => {
        if (settings?.shareA11y) {
          shareString += `${num} Guess${num > 1 ? 'es' : ''}: ${stats.winGuessCount[num]} times\n`;
        } else {
          shareString += emojis[num];
          const boxCount = Math.ceil((stats.winGuessCount[num] / maxWidthDenom) * 10);
          shareString += '- '.padEnd(boxCount * 2, '🟩');
          shareString += `${stats.winGuessCount[num]}\n`;
        }
      });
      shareString += '\n\nhttps://wordle.starwars.guide';
      if (navigator.share) {
        navigator.share({
          text: shareString
        });
      } else {
        navigator.clipboard.writeText(shareString);
        setIsShared(true);
      }
    } catch (ex) {
      logger.logError(logger.EVENTS.SHARE, { content: 'stats', error: ex.toString(), gameNumber, settings, winGame, stats, showModal });
    }
  };

  const closeIt = () => {
    closeModal();
    setIsShared(false);
  };

  React.useEffect(() => {
    if (showModal) {
      if (!showAd && !adShown) {
        setShowAd(true);
      }
      const t = setInterval(() => {
        try {
          const now = new Date();
          const minutes = (23 - now.getHours()) * 60 * 60 * 1000;
          const seconds = minutes + ((60 - now.getMinutes()) * 60 * 1000);
          const millis = seconds + ((60 - now.getSeconds()) * 1000);
          const timeLeft = millis - now.getMilliseconds();
          document.getElementById('countdown').innerText = timeLeft;
        } catch {
          // do nothing
        }
      }, 254);
      setTimeout(() => setGoWide(true), 300);
      return () => {
        clearInterval(t);
        setGoWide(false);
      };
    }
  }, [showModal]);

  React.useEffect(() => {
    setMaxWidthDenom(Object.values(stats.winGuessCount).sort((a, b) => b - a)[0]);
  }, [stats.winGuessCount, showModal]);

  return <>
    {showAd && <Modal.Wrapper>
      <Modal.Window style={{ height: '90vh' }}>
        <Modal.Close onClick={closeAd}>+</Modal.Close>
        <Modal.Body>
          <Ad closeAd={closeAd} adSlot="3673700906" />
        </Modal.Body>
      </Modal.Window>
    </Modal.Wrapper>}
    {(!showAd && showModal) && <Modal.Wrapper>
      <Modal.Window>
        <Modal.Close onClick={closeIt}>+</Modal.Close>
        <Modal.Body>
          <Modal.Title>Statistics</Modal.Title>
          <Styled.StatsWrapper>
            <Styled.Stat>
              <Styled.Number>{stats.gamesPlayed}</Styled.Number>
              <Styled.Caption>Played</Styled.Caption>
            </Styled.Stat>
            <Styled.Stat>
              <Styled.Number>{stats.gamesPlayed > 0 ? Math.round(stats.gamesWon / stats.gamesPlayed * 100) : 0}</Styled.Number>
              <Styled.Caption>Win %</Styled.Caption>
            </Styled.Stat>
            <Styled.Stat>
              <Styled.Number>{stats.streak}</Styled.Number>
              <Styled.Caption>Current<br />Streak</Styled.Caption>
            </Styled.Stat>
            <Styled.Stat>
              <Styled.Number>{stats.maxStreak}</Styled.Number>
              <Styled.Caption>Max Streak</Styled.Caption>
            </Styled.Stat>
            <Styled.Stat>
              <Styled.Number>{stats.hintsUsed}</Styled.Number>
              <Styled.Caption>Hints Used</Styled.Caption>
            </Styled.Stat>
          </Styled.StatsWrapper>
          {gameOver && <>
            <Modal.Title>Winning Word</Modal.Title>
            <Styled.WinningWord><a href={`https://starwars.fandom.com/wiki/${theWord.url}#firstHeading`} target="_blank" title="View the winning word on Wookiepedia" rel="noreferrer">{theWord?.word?.toUpperCase() || theWord?.toUpperCase()}</a></Styled.WinningWord>
            {theWord?.hint && <Styled.Hint>(hint: {theWord.hint.toLowerCase()})</Styled.Hint>}
          </>
          }
          <Modal.Title>Guess Distribution</Modal.Title>
          <Styled.DistributionWrapper>
            {
              [1, 2, 3, 4, 5, 6].map(num =>
                <Styled.GuessRow key={`distro-${num}`}>
                  <Styled.GuessNumber>{num}</Styled.GuessNumber>
                  <Styled.GuessDistribution percentage={stats.winGuessCount[num] / maxWidthDenom} winGame={winGame} highlight={num === currentGuess} goWide={goWide}>{stats.winGuessCount[num]}</Styled.GuessDistribution>
                </Styled.GuessRow>)
            }
          </Styled.DistributionWrapper>
          <Styled.Footer>
            <div>
              <Modal.Title>Next Swordle</Modal.Title>
              <img src={OnTarget} alt="Stay on target gif" />
              <Styled.Countdown id="countdown">0</Styled.Countdown>
            </div>
            <div>
              {gameOver && <Styled.Share onClick={shareGame}>Share Game</Styled.Share>}
              <Styled.Share onClick={shareStats}>Share Stats</Styled.Share>
            </div>
          </Styled.Footer>
        </Modal.Body>
      </Modal.Window>
      {isShared && <Notification message="Copied to clipboard" timeout={1000} onHide={() => setIsShared(false)} />}
    </Modal.Wrapper>
    }
  </>;
};

export default Stats;
