import React, { useEffect } from 'react';
import GlobalStyles from '../../globalStyles';
import GameContext from '../gameState';
import * as Styled from './index.styles';

const BadDomain = () => {

  useEffect(() => {
    console.error('INVALID DOMAIN DETECTED location:', window.location.href, 'referrer:', document.referrer);
    setTimeout(() => {
      if (window.self === window.top) {
        window.location.href = 'https://wordle.starwars.guide';
      } else {
        window.top.location.href = 'https://wordle.starwars.guide';
      }
    }, 3500);
  },[]);

  return <GameContext>
    <GlobalStyles />
    <Styled.Wrapper>
      <iframe src="https://giphy.com/embed/864533yaFNqs8" title="R2D2" width="100%" height="100%" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
      <h3>Uh oh... You are not on the original <a href="https://wordle.starwars.guide" target="_parent">SWordle</a>! This site hijacked it!</h3>
      <iframe src="https://giphy.com/embed/87I8pKmdcAKw8" title="Yoda Fail" width="100%" height="100%" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
      <h3>You should be redirected shortly, if not, try <a href="https://wordle.starwars.guide" target="_parent">clicking here to go to the one and only SWordle</a>. If that doesn&apos;t work, go to wordle.starwars.guide.</h3>
      <iframe src="https://giphy.com/embed/3o84sw9CmwYpAnRRni" title="do it" width="100%" height="100%" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
      <h3>See you soon!</h3>
      <iframe src="https://giphy.com/embed/xTiTnDCzB5QjCb9GRq" title="home" width="100%" height="100%" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
    </Styled.Wrapper>
  </GameContext>;
};


export default BadDomain;