import React from 'react';
import { unobfs } from '../../obfs';
import { useGameContext } from '../gameState';
import Notification from '../notification';
import * as logger from '../../logging';

import * as Styled from './index.styles';

const Import = ({ closeModal }) => {
  const { actions: { importStats} } = useGameContext();
  const [isSaved, setIsSaved] = React.useState(false);
  const [statsVal, setStatsVal] = React.useState('');

  const save = () => {
    importStats(statsVal);
    setIsSaved(true);
    logger.logInfo(logger.EVENTS.SYNC, 'import saved');
  };

  const onSave = () => {
    setIsSaved(false);
    closeModal();
  };

  return <Styled.Wrapper>
    <Styled.Note>Paste your exported value below and click Save Stats.<strong>WARNING</strong> this will overwrite any existing stats in this browswer.</Styled.Note>
    <Styled.DataInput value={statsVal} onChange={(e) => setStatsVal(e.target.value)} />
    <Styled.Button onClick={save}>Save Stats</Styled.Button>
    {isSaved && <Notification message="Saved" timeout={1000} onHide={onSave} />}
  </Styled.Wrapper>;
};

export default Import;