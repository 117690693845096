import React from 'react';
import styled, { css, keyframes } from 'styled-components';

export const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  width: 100%;
  height: 9vh;
`;

export const Left = styled.div`
  display: flex;
  width: 25%;
  padding-left: 10px;
  height: 8vh;
  align-items: center;
`;

export const Center = styled(Left)`
  width: 50%;
  justify-content: center;
  padding: 0;
  > img {
    height: 50%;
    max-height: 9vh;

    @media only screen and (max-width: 400px) {
      height: 22px;
    }
  }
`;

export const Right = styled(Left)`
  justify-content: flex-end;
  padding-right: 10px;
`;

export const Title = styled.h1`
  margin: 0;
  `;

const wiggle = keyframes`
  0% {
    filter: brightness(2) saturate(2);
    transform: rotate(0deg);
  }
  15% {
    filter: brightness(2) saturate(2);
    transform: rotate(20deg);
  }
  30% {
    filter: brightness(2) saturate(2);
    transform: rotate(0deg);
  }
  45% {
    filter: brightness(2) saturate(2);
    transform: rotate(-20deg);
  }
  60% {
    filter: brightness(2) saturate(2);
    transform: rotate(0deg);
  }
  75% {
    filter: brightness(2) saturate(2);
    transform: rotate(20deg);
  }
  90% {
    filter: brightness(2) saturate(2);
    transform: rotate(0deg);
  }
  100% {
    filter: brightness(2) saturate(2);
    transform: rotate(-20deg);
  }
`;

export const Info = styled(({ animate, disabled, ...rest }) => <button {...rest} />)`
  border: 0;
  background: transparent;
  justify-content: center;
  align-content: center;
  padding: 0;
  cursor: pointer;
  margin-left: 5px;
  display: flex;

  > img {
    width: 80%;
    max-width: 25px;
    transition: transform 400ms ease-in-out;
    &:hover {
      transform: rotate(-100deg);
    }
  }

  ${({ disabled }) => disabled && css` // dont want disabled to go to the button obj
    filter: saturate(0) opacity(.5);
  `}

  ${({ animate }) => animate && css`
      animation: ${wiggle} 500ms ease-in-out 1500ms 4;
  `}
`;

export const Alert = styled(({ showAlert, ...rest }) => <span {...rest} />)`
  position: relative;

  ${({ showAlert }) => showAlert && css`
    ::before {
      content: '';
      display: flex;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      position: absolute;
      right: 0px;
      bottom: 0px;
      background-color: rgb(${({theme}) => theme.colors.red});
    }
  `}
`;

export const Version = styled.div`
  font-family: 'AurebeshDroid';
  font-size: 12px;
  position: absolute;
  top: 5px;
  left: 5px;
  color: rgb(${({ theme }) => theme.colors.grey});
`;