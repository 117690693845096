import React from 'react';
import hintImg from '../../images/hint.png';
import Box from '../box';

import * as Styled from './index.styles';
import * as Modal from '../modal/index.styles';
import Ad from '../ad';

const sample = {
  green: {
    state: 3,
    letter: 'g'
  },
  yellow: {
    state: 2,
    letter: 'y'
  },
  grey: {
    state: 1,
    letter: 'r'
  }
};

const HowToPlay = () => <>
  <p>Guess the Star Wars word in 6 tries.</p>
  <p>Each guess has to be 5 letters, think plurals too!</p>
  <p>The keyboard includes numbers and dashes, think of your favorite droids and more!</p>
  <p>The color of the letter indicates its status.</p>
  <Ad adSlot="9730970651" adFormat="fluid" layoutKey="-gc+3r+68-9q-29" />
  <p><strong>Examples:</strong></p>
  <Styled.Example>
    <Box letter={sample.green} /> Indicates the correct letter is in the correct position
  </Styled.Example>
  <Styled.Example>
    <Box letter={sample.yellow} /> Indicates this letter is in the word, but is in the wrong position
  </Styled.Example>
  <Styled.Example>
    <Box letter={sample.grey} /> Indicates an incorrect letter and does not exist in the word
  </Styled.Example>
  <p>There are over 14,000 words to choose from! <a href="https://starwars.guide/swordle-word-list" title="View SWordle Word List" target="_blank" rel="noreferrer">View Entire Word List</a></p>
  <p>Need a hint? After your 3rd try, press the hint button, it will be green in the top left: <img src={hintImg} alt="Example of the hint button" style={{ maxHeight: '1.8vh' }} />.</p>
  <p>&nbsp;</p>
  <Modal.Title>Credits</Modal.Title>
  <p>Created with ❤️ by&nbsp;
    <a href="https://twitter.com/aurebeshfiles" target="_blank" rel="noreferrer">@AurebeshFiles</a>
  </p>
  <p><a href="https://www.buymeacoffee.com/davidlozzi" target="_blank" rel="noreferrer">Buy AurebeshFiles a Bantha Milk</a> or <a href="https://www.patreon.com/aurebeshfiles" target="_blank" rel="noreferrer">Support on Patreon</a></p>
  <p>
    Design by&nbsp;
    <a href="https://twitter.com/StarWars5W" target="_blank" rel="noreferrer">@StarWars5W</a>
  </p>
  <p>
    Fonts by&nbsp;
    <a href="https://aurekfonts.github.io/" target="_blank" rel="noreferrer">AurekFonts</a>
  </p>
  <p>&nbsp;</p>
  <Modal.Title>More by AurebeshFiles</Modal.Title>
  <p><a href="https://timeline.starwars.guide" target="_blank" rel="noreferrer">Ultimate Star Wars Timeline</a></p></>;

export default HowToPlay;