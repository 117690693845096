import React from 'react';
import * as logger from '../../logging';
import Info from '../info';
import logo from '../../images/logo.png';
import info from '../../images/info.png';
import settingsImg from '../../images/settings.png';
import statsImg from '../../images/stats.png';
import hintImg from '../../images/hint.png';

import * as Styled from './index.styles';
import Settings from '../settings';
import { useGameContext } from '../gameState';
import Hint from '../hint';
import Notification from '../notification';
import { lastNewsItemDate } from '../news';

const Header = () => {
  const { version, currentGuess, loading, settings: { lastDayIndexRefresh, lastNewsDay }, actions: { showStats } } = useGameContext();
  const hasNewNews = lastNewsItemDate > lastNewsDay;
  const [showInfo, setShowInfo] = React.useState(false);
  const [showSettings, setShowSettings] = React.useState(false);
  const [showHint, setShowHint] = React.useState(false);

  const openInfo = () => {
    logger.logInfo(logger.EVENTS.OPEN, { panel: 'info' });
    setShowInfo(true);
  };

  const openSettings = () => {
    logger.logInfo(logger.EVENTS.OPEN, { panel: 'settings' });
    setShowSettings(true);
  };

  const openStats = () => {
    logger.logInfo(logger.EVENTS.OPEN, { panel: 'stats' });
    showStats();
  };

  const openHint = () => {
    logger.logInfo(logger.EVENTS.OPEN, { panel: 'hint' });
    setShowHint(true);
  };

  return <Styled.HeaderWrapper>
    <Styled.Left>
      <Styled.Alert showAlert={hasNewNews} onClick={openInfo}><Styled.Info><img src={info} alt="Info button" /></Styled.Info></Styled.Alert>
      <Styled.Info onClick={openHint} disabled={currentGuess < 3} animate={currentGuess === 3}><img src={hintImg} alt="Hint button" /></Styled.Info>
      {showInfo && <Info onClose={() => setShowInfo(false)} />}
    </Styled.Left>
    <Styled.Center>
      <img src={logo} alt="SWordle" />
    </Styled.Center>
    <Styled.Right>
      <Styled.Info onClick={openStats}><img src={statsImg} alt="Stats button" /></Styled.Info>
      <Styled.Info onClick={openSettings}><img src={settingsImg} alt="Info button" /></Styled.Info>
      {showSettings && <Settings onClose={() => setShowSettings(false)} />}
      {showHint && <Hint onClose={() => setShowHint(false)} />}
      {currentGuess === 3 && <Notification message="a hint is available (top left)" timeout={2000} />}
      <Styled.Version>g{lastDayIndexRefresh}v{version}</Styled.Version>
    </Styled.Right>
  </Styled.HeaderWrapper>;
};

export default Header;