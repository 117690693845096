import React from 'react';
import styled, { css } from 'styled-components';

export const Title = styled.h2`
  display: flex;
  margin: 0;
  font-size: 2.2vh;
  text-transform: uppercase;
  justify-content: center;
  justify-items: center;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.title};
`;

export const StatsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: 20px 0;
`;

export const Stat = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  div {
    display: flex;
    justify-content: center;
  }
`;

export const Number = styled.div`
  font-size: 3vh;
`;

export const Caption = styled.div`
  font-size: 1.2vh;
`;

export const WinningWord = styled.p`
  text-align: center;
  margin-bottom: 0;
  font-size: 1.8vh;
`;

export const Hint = styled.p`
  text-align: center;
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 1.3vh;
`;

export const DistributionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin: 20px 0;

  @media only screen and (max-width: 450px) {
    padding: 0;
  }
`;

export const GuessRow = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

export const GuessNumber = styled.div`
  display: flex;
  width: 10px;
  font-size: 16px;
  margin-right: 10px;
`;

export const GuessDistribution = styled(({ percentage, goWide, highlight, winGame, ...rest }) => <div { ...rest } />)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  padding-right: 5px;
  border-radius: 0 8px 8px 0;
  font-size: 14px;
  transition: width 500ms ease-in-out;
  width: 15px;
  min-width: 15px;
  color: rgb(${({ theme }) => theme.colors.black});
  ${({ highlight, winGame, theme }) => css`
    background-color: ${winGame && highlight ? `rgb(${theme.colors.green})` : `rgb(${theme.colors.grey})`};
  `}
  ${({ goWide }) => goWide && css`
    width: ${({ percentage }) => 100 * percentage}%;
  `}
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px;

  @media only screen and (max-width: 450px) {
    padding: 0;
  }

  > div {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  img {
    width: 100%;
  }
`;

export const Countdown = styled.div`
  border: solid 1px rgb(${({ theme }) => theme.colors.yellow});
  padding: 1vw;
  border-radius: 10px;
  margin-top: 10px;
  color: rgb(${({ theme }) => theme.colors.red});
  font-family: ${({ theme }) => theme.fonts.title};
  font-size: 26px;
  width: 80%;
  text-align: center;

  @media only screen and (max-width: 700px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 450px) {
    width: 90%;
    font-size: 14px;
  }
`;

export const Share = styled.button`
  border: 0;
  border-radius: 10px;
  font-size: 20px;
  background-color: rgb(${({ theme }) => theme.colors.green});
  padding: 10px;
  color: rgb(${({ theme }) => theme.colors.black});
  text-transform: uppercase;
  margin: 10px 0 10px 15px;
  @media only screen and (max-width: 700px) {
    font-size: 15px;
  }
`;