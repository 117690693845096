import React from 'react';

import * as logger from '../../logging';
import * as Styled from './index.styles';
import * as Modal from '../modal/index.styles';
import { useGameContext } from '../gameState';

const Hint = ({ onClose }) => {
  const { currentGuess, showingHint, theWord, actions: { showHint } } = useGameContext();
  const isEnabled = currentGuess >= 3;
  
  return <Modal.Wrapper>
    <Modal.Window>
      <Modal.Close onClick={onClose}>+</Modal.Close>
      <Modal.Body>
        <Modal.Title>Game Hint</Modal.Title>
        {!isEnabled && <Styled.Content>No hint yet! You&apos;ll be able to see the hint after your 3rd guess.</Styled.Content>}
        {isEnabled && <>
          <Styled.Content>You&apos;re {currentGuess} tries in. You can now see a hint if you would like.</Styled.Content>
          <Styled.ShowHint onClick={showHint} disabled={showingHint}>Show Hint</Styled.ShowHint>
          {showingHint && <Styled.Hint>{theWord.hint}</Styled.Hint>}
        </>}
      </Modal.Body>
    </Modal.Window>
  </Modal.Wrapper>;
};

export default Hint;