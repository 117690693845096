import React from 'react';
import styled, { css } from 'styled-components';
import * as Modal from '../modal/index.styles';

export const Body = styled.div`

`;

export const Title = styled.h2`
  display: flex;
  margin: 0;
  font-size: 2.2vh;
  text-transform: uppercase;
  justify-content: center;
  justify-items: center;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.title};
`;

export const Note = styled.p`
  font-size: 16px;
`;

export const Tabs = styled.div`
  display: flex;
`;

export const Tab = styled(({ isActive, ...rest }) => <Modal.Title {...rest} />)`
  width: 12vw;
  ${({ isActive, theme }) => !isActive && css`
    font-size: 2vh;
    color: rgb(${theme.colors.grey});
  `}
  @media only screen and (max-width: 700px) {
    width: 30vw;
  }

`;

export const Content = styled.div`
  padding-top: 10px;
`;